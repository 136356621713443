.legend {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: absolute;
    bottom: 1rem;
    margin-left: auto;
    margin-right: auto;
    width: max-content;
    border-radius: 10px;
    color: #fff;
    padding: 10px;
    text-align: center;
    opacity: 1;
    display: block;
    left: 0;
    right: 0;
}

/* .carousel .slide {
    padding-bottom: 6rem;
} */

/* .carousel:hover .slide .legend {
    opacity: 1; } */

.bgBlueviolet {
    background-color: blueviolet;
}

.bgMint {
    background-color: mediumseagreen;
}

.bgAutumn {
    background-color: rgb(194, 106, 0);
}
